strong {
    font-weight: 500;
}

th, td:first-child{
    font-weight: 500;
}

th, td {
    padding: 20px 10px;
    text-align: left;
}

th {
    border-bottom: 1px solid white;
    padding-bottom: 10px;
}

th:first-child, td:first-child {
    width: 200px;  
}


@media screen and (max-width: 769px) and (min-width: 491px){
    th:first-child, td:first-child {
        width: 150px;  
    }
}

@media screen and (max-width: 491px){
    th:first-child, td:first-child {
        width: 90px;  
    }

    th, td {
        padding: 20px 7px;
        text-align: left;
    }
}

.privacy-policy ol span {
    position: relative;
    left: 30px;
}

.privacy-policy ol li {
    margin-bottom: 1em;
}

.formal-li {
    margin-left: 50px;
    margin-right: 50px;
}

.formal-title {
    font-weight: 500;
    margin-right: 20px;
}

.privacy-policy ol {
    padding-right: 30px;
    margin-left: -20px;
}

@media screen and (max-width: 491px){
    .formal-li {
        margin-left: 30px;
        margin-right: 30px;
    }
}
